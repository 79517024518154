// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-installation-info-js": () => import("./../../../src/pages/installation-info.js" /* webpackChunkName: "component---src-pages-installation-info-js" */),
  "component---src-pages-our-service-js": () => import("./../../../src/pages/our-service.js" /* webpackChunkName: "component---src-pages-our-service-js" */),
  "component---src-pages-products-architectural-features-js": () => import("./../../../src/pages/products/architectural-features.js" /* webpackChunkName: "component---src-pages-products-architectural-features-js" */),
  "component---src-pages-products-balls-and-bases-js": () => import("./../../../src/pages/products/balls-and-bases.js" /* webpackChunkName: "component---src-pages-products-balls-and-bases-js" */),
  "component---src-pages-products-balustrading-js": () => import("./../../../src/pages/products/balustrading.js" /* webpackChunkName: "component---src-pages-products-balustrading-js" */),
  "component---src-pages-products-corbels-js": () => import("./../../../src/pages/products/corbels.js" /* webpackChunkName: "component---src-pages-products-corbels-js" */),
  "component---src-pages-products-keystones-js": () => import("./../../../src/pages/products/keystones.js" /* webpackChunkName: "component---src-pages-products-keystones-js" */),
  "component---src-pages-products-pier-caps-js": () => import("./../../../src/pages/products/pier-caps.js" /* webpackChunkName: "component---src-pages-products-pier-caps-js" */),
  "component---src-pages-products-porticos-js": () => import("./../../../src/pages/products/porticos.js" /* webpackChunkName: "component---src-pages-products-porticos-js" */),
  "component---src-pages-products-quions-js": () => import("./../../../src/pages/products/quions.js" /* webpackChunkName: "component---src-pages-products-quions-js" */),
  "component---src-pages-products-strings-and-plinths-js": () => import("./../../../src/pages/products/strings-and-plinths.js" /* webpackChunkName: "component---src-pages-products-strings-and-plinths-js" */),
  "component---src-pages-products-wall-coping-js": () => import("./../../../src/pages/products/wall-coping.js" /* webpackChunkName: "component---src-pages-products-wall-coping-js" */),
  "component---src-pages-products-window-cills-and-heads-js": () => import("./../../../src/pages/products/window-cills-and-heads.js" /* webpackChunkName: "component---src-pages-products-window-cills-and-heads-js" */),
  "component---src-pages-products-window-surrounds-js": () => import("./../../../src/pages/products/window-surrounds.js" /* webpackChunkName: "component---src-pages-products-window-surrounds-js" */)
}

